import { formatCenterName } from "@repo/utils";
import type { CenterInfos, GTM } from "./types";
import type { RenderBoxNameParams} from "@web/utils";
import { renderBoxNameString } from "@web/utils";
import type { API } from "@web/trpc/types";

/**
 * Format a cart line data for analytics reporting 
 * 
 * @param infos 
 * @returns 
 */
export function formatProductLine(cart: API.Cart, line: API.Cart['lines'][0], index: number) : GTM.ProductItemPayload {
  const centerName = formatCenterName(cart.center);
  // On ne prend que les infos du box, on s'en fou des frais de dossier
  const output: GTM.ProductItemPayload = {
    index,
    item_id: line.box.productId,
    item_name: formatProductName({
      boxType: {
        volume: line.box.volume,
        surface: line.box.surface
      }
    }),
    // Nom du centre format "town - bigCity" -> "Saint-Herblain - Nantes"
    item_brand: centerName,
    affiliation: centerName,
    location_id: cart.center.googleLocationId,
    // Prix unitaire du box de base (pas prendre la promo)
    price: formatPrice(line.box.price.ttc),
    // tax --> ne pas mettre on veut du TTC
    quantity: line.qty ?? 1,
  };

  if (line.box.discountedPrice !== undefined)
    output.discount = line.box.discountedPrice?.ttc;

  if (cart.coupon && line.box.couponApplied) 
    output.coupon = cart.coupon.code;

  return output;
}

type FormatProductDataArgs = {
  center: CenterInfos, 
  product: {
    id: string, 
    centerId: string,
    allTaxesIncludedPrice: number,
  } & FormatProductNameArgs,
  index?: number,
}

export function formatProductData(infos: FormatProductDataArgs) {
  const centerName = formatCenterName(infos.center);
  const output: Record<string, unknown> = {
    item_id: infos.product.id,
    item_name: formatProductName(infos.product),
    item_brand: centerName,
    affiliation: centerName,
    price: formatPrice(infos.product.allTaxesIncludedPrice),
    // tax --> ne pas mettre on veut du TTC
    quantity: 1,
  };

  return output;
}

/**
 * Format a cart product lines for analytics reporting
 * @param cart 
 * @returns 
 */
export function formatCartProductLines(cart: API.Cart) : GTM.ProductItemPayload[] {
  return cart.lines
    .map((line, index) =>
      formatProductLine(cart, line, index),
    )
}

/**
 * Formats the price for analytics reporting
 * 
 * @param price 
 * @returns 
 */
export function formatPrice(price: number): number {
  return parseFloat(price.toFixed(2));
}

export type FormatProductNameArgs = {name: string} | RenderBoxNameParams;

/**
 * Formats the product name for analytics reporting
 * 
 * @param product 
 * @returns 
 */
export function formatProductName(product: FormatProductNameArgs): string {
  if ('name' in product) return product.name;

  return `Box ${renderBoxNameString(product)}`;
}