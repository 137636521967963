import type { AddressWithCountry } from "@repo/utils";
import { z } from "zod";

export type CenterInfos = {
  town: string;
  bigCity?: string | null;
  locationCode?: string;
};

export type UserData = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  address: AddressWithCountry
};

export type UserLeadData = {
  email: string;
  phone?: string;
};

export type AgenceData = {
  id: string;
  town: string;
  bigCity?: string | null;
};

export namespace GTM {
  export const productItemPayloadSchema = z.object({
    item_id: z.string(),
    item_name: z.string(),
    affiliation: z.string().optional(),
    coupon: z.string().optional(),
    discount: z.number().optional(),
    index: z.number(),
    item_brand: z.string().optional(),
    item_category: z.string().optional(),
    item_list_id: z.string().optional(),
    item_list_name: z.string().optional(),
    location_id: z.string().optional(),
    price: z.number(),
    quantity: z.number(),
  });
  export type ProductItemPayload = z.infer<typeof productItemPayloadSchema>;

  export const beginCheckoutPayloadSchema = z.object({
    currency: z.literal('EUR'),
    value: z.number(),
    coupon: z.string().optional(),
    agence: z.string(),
    agence_id: z.string(),
    items: productItemPayloadSchema.array(),
  });
  export type BeginCheckoutPayload = z.infer<typeof beginCheckoutPayloadSchema>;

  export const addShippingInfoPayloadSchema = beginCheckoutPayloadSchema;
  export type AddShippingInfoPayload = BeginCheckoutPayload;

  export const addPaymentInfoPayloadSchema = beginCheckoutPayloadSchema.extend({
    transaction_id: z.string(),
  });
  export type AddPaymentInfoPayload = z.infer<typeof addPaymentInfoPayloadSchema>;

  export const purchasePayloadSchema = addPaymentInfoPayloadSchema;
  export type PurchasePayload = AddPaymentInfoPayload;

  export const addToCartPayloadSchema = beginCheckoutPayloadSchema;
  export type AddToCartPayload = BeginCheckoutPayload;
  
  export const addToWishListPayloadSchema = beginCheckoutPayloadSchema;
  export type AddToWishListPayload = BeginCheckoutPayload;

  export const removeFromCartPayloadSchema = beginCheckoutPayloadSchema;
  export type RemoveFromCartPayload = BeginCheckoutPayload;
}
